<template>
  <v-navigation-drawer
    v-model="close"
    style="background-color: #ffffff"
    app
    light
    :mini-variant.sync="mini"
  >
    <v-list-item style="padding: 4px 10px;">
      <v-list-item-title
        class=" font text-uppercase font-weight-bold blue-grey--text ml-3 font-weight-bold"
        >{{
          profile ? profile.organizationName : "ADMIN PORTAL"
        }}</v-list-item-title
      >

      <v-btn icon @click.stop="mini = !mini">
        <v-icon color="#04a9f3">mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>
    <div class="side_bar">
      <v-list dense>
        <v-list-item
          v-for="item in linkItem"
          :key="item.title"
          router
          class=" font "
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class=" font"
              v-text="item.title"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                class=" font "
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class=" font"
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="subItem.link"
            router
          >
            <v-list-item-content>
              <v-list-item-title
                class=" font blue-grey--text"
                style="text-transform: capitalize; font-size: 12px;"
                v-text="subItem.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    drawer: {
      type: Boolean,
      default: true
    },
    profile: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      mini: false,
      close: true,
      linkItem: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          link: { name: "admin.home.dashboard" }
        }
      ],
      items: [
        {
          icon: "mdi-account-multiple-plus",
          title: "Staff",
          items: [
            {
              icon: "mdi-account-group",
              title: "All Staff",
              link: { name: "admin.staffs.all" }
            }
          ]
        },

        {
          icon: "mdi-face-agent",
          title: "Customers",
          active: true,
          items: [
            {
              icon: "mdi-account-multiple",
              title: "Customers",
              link: { name: "admin.customers.all" }
            }
          ]
        },
        {
          icon: "mdi-school",
          title: "Schools",
          active: true,
          items: [
            {
              icon: "mdi-account-multiple",
              title: "All Schools",
              link: { name: "admin.schools.all" }
            }
          ]
        },
        {
          icon: "mdi-cash-multiple",
          title: "Transactions",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Balances",
              link: { name: "admin.balances.all" }
            },
            {
              icon: "mdi-minus-box",
              title: "Transactions",
              link: { name: "admin.transactions.all" }
            },
            {
              icon: "mdi-minus-box",
              title: "Exports",
              link: { name: "admin.transactions.exports" }
            }
          ]
        },
        {
          icon: "delete",
          title: "Trash",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Staff",
              link: { name: "admin.trash.staff" }
            },
            {
              icon: "mdi-minus-box",
              title: "Members",
              link: { name: "admin.trash.members" }
            }
          ]
        }
      ]
    };
  },
  watch: {
    drawer(value) {
      this.close = value;
    }
  },

  created() {
    this.close = this.drawer;
  }
};
</script>

<style scoped></style>
